import { browser } from '$app/environment';

/**
 *
 * @param url
 * @returns The domain without www.
 */
export function getDomain(url: string, keepWWW = false): string {
  if (!url) return;
  if (url.startsWith('https://')) url = url.substring(8);
  if (url.startsWith('http://')) url = url.substring(7);
  if (url.startsWith('//')) url = url.substring(2);
  url = new URL('http://' + url).hostname;
  if (!keepWWW) url = url.replace('www.', '');
  return url;
}

export function isCurrentWebsite(
  url: string,
  expectedDomain = window.location.hostname,
  keepWWW = false
): boolean {
  // getDomain(expectedDomain) removes www, so keep it
  // console.log({expectedDomain, ...(browser ? {loc: window.location, window} : {})});
  if(expectedDomain === "") expectedDomain = window.location.ancestorOrigins[0]; // if <iframe srcdoc>, allow. example: https://members.dotcomtruths.com/school/course/templates-and-coaching-membership/lecture/1455814
  return getDomain(expectedDomain, keepWWW) === getDomain(url, keepWWW);
}
