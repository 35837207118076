import { onSetLanguageTag } from '../paraglide/runtime';
import { writable } from 'svelte/store';

export function isLangRtl(lang: string) {
  console.log('isLangRtl', lang);
  if (['he'].includes(lang)) return true;
  return false;
}
export const rtl = writable(false);

export function initRtl(tag: string) {
  rtl.set(isLangRtl(tag));
}
onSetLanguageTag(initRtl);

export function getLanguageName(tag: string) {
  let languageNames = new Intl.DisplayNames([tag], { type: 'language' });
  return languageNames.of(tag);
}
