<script context="module">
  import { COLORS } from './utils/constants';
  import { createParticle, isOutOfBounds, renderParticle, updateParticle } from './utils/particle';
  const renderParticles = (context, particles) => {
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);
    for (let i = 0; i < particles.length; ++i) {
      renderParticle(context, particles[i]);
    }
  };
  /**
   * @returns {boolean} Returns false if no more confettis on the screen.
   */
  const updateParticles = (context, particles, dt, onUpdate) => {
    let livingParticles = particles.length;
    for (let i = 0; i < particles.length; ++i) {
      const p = particles[i];
      if (p.dead) {
        livingParticles--;
      } else {
        updateParticle(p, dt);
        if (isOutOfBounds(context, p)) p.dead = true;
        if (onUpdate) onUpdate(p, dt);
      }
    }
    return livingParticles > 0;
  };
  const start = (
    canvas,
    onCompleted,
    particleCount,
    origin,
    force,
    angle,
    spread,
    styles,
    onCreate,
    onUpdate
  ) => {
    const context = canvas.getContext('2d');
    if (!context) throw new Error('No context?');
    const particles = Array.from({ length: particleCount }, (_, i) =>
      createParticle(context, origin, force, angle, spread, styles, onCreate, i)
    );
    let frameId, t;
    const run = (_t) => {
      renderParticles(context, particles);
      const stillRunning = updateParticles(context, particles, (_t - t) / 1e3, onUpdate);
      if (stillRunning) {
        t = _t;
        frameId = requestAnimationFrame(run);
      } else {
        onCompleted();
      }
    };
    t = performance.now();
    frameId = requestAnimationFrame(run);
    return () => {
      cancelAnimationFrame(frameId);
    };
  };
</script>

<script lang="ts">
  import { onMount, createEventDispatcher } from 'svelte';
  /**
   * A list of render styles to use for the confetti. Each confetti will be assigned a random value from the list.
   * The values can either be valid HTML colors or an HTMLImageElement.
   * @default ['hotpink','gold','dodgerblue','tomato','rebeccapurple','lightgreen','turquoise']
   * @example
   * ```
   * <Confetti colors={['red', '#ff0000', 'hsl(250, 54%, 85%)']} />
   * ```
   */
  export let styles = COLORS;
  /**
   * The number of particles to create.
   * @default 50
   * @example
   * ```
   * <Confetti particleCount={100} />
   * ```
   */
  export let particleCount = 50;
  /**
   * The origin position of the confetti. If this is not passed in, the confetti will fall from the top of the screen.
   * @default undefined
   * @example
   * ```
   * <Confetti origin={[50, 50]} />
   * ```
   */
  export let origin = undefined;
  /**
   * The force of the burst. A larger number will shoot the confetti faster and further. Has no effect if origin isn't passed in.
   * @default 15
   * @example
   * ```
   * <Confetti origin={[50, 50]} force={15} />
   * ```
   */
  export let force = 15;
  /**
   * Angle in degrees of the burst. This can be used together with spread to create a directed burst. Has no effect if origin isn't passed in.
   * @default 0
   * @example
   * ```
   * <Confetti origin={[50, 50]} angle={90} />
   * ```
   */
  export let angle = 0;
  /**
   * The spread in degrees of the burst. This can be used together with angle to create a directed burst. Has no effect if origin isn't passed in.
   * @default 360
   * @example
   * ```
   * <Confetti origin={[50, 50]} spread={90} />
   * ```
   */
  export let spread = 360;
  /**
   * By default, each particle is created with some random variation. The initial values of each particle can be overriden using the onCreate callback.
   * @default undefined
   * @example
   * ```
   * <Confetti
   *   onCreate={(particle) => {
   *     particle.style = 'blue';
   *     particle.x = window.innerWidth / 2;
   *     return particle;
   * 	 }}
   * />
   * ```
   */
  export let onCreate = undefined;
  /**
   * The onUpdate callback can be used to modify the particle on each frame.
   * @default undefined
   * @example
   * ```
   * <Confetti
   *   onUpdate={(particle, deltaTime) => {
   *     if (particle.angle < 0 && particle.da < 0) {
   *       particle.da *= -1;
   * 		 }
   * 	 }}
   * />
   * ```
   */
  export let onUpdate = undefined;
  const dispatch = createEventDispatcher();
  let offscreenCanvas: OffscreenCanvas;
  let canvas;
  let w: number, h: number;

  const handleResize = () => {
    w = document.getElementsByClassName('confetties-iframe')?.[0]?.clientWidth || window.innerWidth;
    h =
      document.getElementsByClassName('confetties-iframe')?.[0]?.clientHeight || window.innerHeight;
    if (offscreenCanvas) {
      offscreenCanvas.width = w;
      offscreenCanvas.height = h;
    }
  };
  onMount(() => {
    handleResize();

    addOverride();

    offscreenCanvas = new OffscreenCanvas(w, h);

    const stopAnimation = start(
      offscreenCanvas,
      () => dispatch('completed'),
      particleCount,
      origin,
      force,
      angle,
      spread,
      styles,
      onCreate,
      () => {
        if (onUpdate) onUpdate();
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, w, h);
        ctx.drawImage(offscreenCanvas, 0, 0);
      }
    );

    return () => {
      stopAnimation();
      window.removeEventListener('resize', handleResize);
    };
  });

  function addOverride() {
    /**
     * This Ovverride:
            *, *:after, *:before {
                pointer-events: auto !important;
            }

            introduced in this page: https://lab.campaigncoach.ai/welcometutorials
     */
    const style = document.createElement('style');
    style.innerHTML = `
                html body .confetties-iframe * {
                    pointer-events: none !important;
                }
            `;
    document.body.appendChild(style);
  }
</script>

<!-- Fix resize re-run confetti problem? -->
 <svelte:window on:resize={handleResize} />

<!-- {w} {h} -->

<!-- <svelte:window bind:innerWidth={w} bind:innerHeight={h} /> -->
<canvas class=" " id="confettipage-canvas" bind:this={canvas} width={w} height={h} />

{@html `<style>
    canvas#confettipage-canvas {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      z-index: 999999;
    }
  </style>`}
