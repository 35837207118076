import type { AnimationPallete } from '$lib/ConfettiAnimation/ConfettiAnimation.types';

export const defaultColorPalletes: AnimationPallete[] = [
  ['#332f48', '#f2d9e6', '#ec4c4c', '#772c93'],
  ['#fcfff4', '#3b395e', '#6f6ca6', '#59bec2'],
  ['#f8fcfb', '#342926', '#8b7368', '#f7bf9b'],
  ['#64e4ec', '#283145', '#0078f5', '#efe5e1'],
  ['#e1a750', '#001c2d', '#455c57', '#cad7ca'],
  ['#fbfdfc', '#370787', '#8661f4', '#f791f0'],
  ['#faf9fd', '#10396a', '#f33452', '#faa742'],
  ['#67411c', '#792061', '#eb60a5', '#fbf8ed'],
  ['#ebeff4', '#221d18', '#7939e1', '#c0b5d2'],
  ['#fffcfb', '#712b3d', '#da604b', '#ebb431'],
  ['#433d5d', '#ffcd40', '#f6f6f8', '#5e6974'],
  ['#bed3af', '#150f00', '#5c4710', '#c9c734'],
  ['#094bcf', '#feb959', '#feffff', '#6b8eed'],
  ['#fbfefd', '#00186a', '#003afb', '#7aa8f7'],
  ['#38251e', '#f3b74d', '#e47a54', '#a4422c'],
  ['#fbfafb', '#262f4c', '#e82c5f', '#f8c1ad'],
  ['#fcfefe', '#5a1a5a', '#ec3c51', '#fc9f2b'],
  ['#fd0094', '#9cc835', '#f5e9c8', '#ee7705'],
  ['#fdfffc', '#273788', '#3b76fc', '#ffaf09'],
  ['#eaa215', '#1d181a', '#3175ad', '#dfd0b8'],
  ['#0f2647', '#59dcfb', '#9e7cfd', '#9631f2'],
  ['#22b4a1', '#ff6262', '#faa534', '#f7fac5'],
  ['#f83a63', '#0bd5be', '#fbfafa', '#4f2e8c'],
  ['#006d56', '#c7d800', '#f9f9fb', '#84ba32'],
  ['#e94459', '#22c8b2', '#f6d55e', '#50032b'],
  ['#0c0f11', '#96b78b', '#bd9a5b', '#b62a2b'],
  ['#275664', '#f8af08', '#e65f27', '#2b2626'],
  ['#ffe7db', '#162e5b', '#018577', '#8be3dc'],
  ['#e84b5e', '#f9db05', '#fefffc', '#ffbe24'],
  ['#3564bd', '#f6c81f', '#f9fefd', '#9fb0ff'],
  ['#f6fbfa', '#47220d', '#cd3e71', '#ada7a6'],
  ['#ec1e45', '#fce855', '#f6fbf6', '#fe7e99'],
  ['#fffdff', '#5c3270', '#cf2541', '#cda0a1'],
  ['#fcfdfc', '#203472', '#276f9b', '#0ab5d8'],
  ['#f8f8fb', '#2b2727', '#9f565b', '#dea69a'],
  ['#f3f0db', '#2e2c4c', '#e73e25', '#f7ae22']
];
