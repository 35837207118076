import { dev } from '$app/environment';
import { initializeApp } from 'firebase/app';
import {
  addDoc,
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  where,
  type DocumentData,
  type Unsubscribe
} from 'firebase/firestore';

import { PUBLIC_FIREBASE } from '$env/static/public';
import { getAuth, onAuthStateChanged, type User } from 'firebase/auth';
import { derived, writable, type Readable, type Writable } from 'svelte/store';
import type { UserDoc } from './UserDoc';
import type { ConfettiDoc } from './ConfettiDoc';
import type { GeneralStatsDoc } from './types/GeneralStatsDoc.type';
import type { GameStatsDoc } from './types/GameStatsDoc.type';

const firebaseConfig = JSON.parse(PUBLIC_FIREBASE);

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
export const auth = getAuth(app);

/**
 * @returns a store with the current firebase user
 */
function userStore() {
  let unsubscribe: () => void;

  if (!auth || !globalThis.window) {
    console.warn('Auth is not initialized or not in browser');
    const { subscribe } = writable<User | null>(null);
    return {
      subscribe
    };
  }

  const { subscribe } = writable(auth?.currentUser ?? null, (set) => {
    unsubscribe = onAuthStateChanged(auth, (user) => {
      set(user);
    });

    return () => unsubscribe();
  });

  return {
    subscribe
  };
}

/**
 * Thanks fireship:
 * https://github.com/fireship-io/fkit-course/blob/ce1b40a030c5461ba4e449a2c45eae6fdfcb4543/src/lib/firebase.ts
 */
export const user = userStore();

/**
 * @param  {string} path document path or reference
 * @param  {any} startWith optional default data
 * @returns a store with realtime updates on document data
 */
export function docStore<T>(path: string) {
  let unsubscribe: () => void;

  // console.log('docStore: path', path);

  const docRef = doc(db, path);

  const { subscribe } = writable<T | null>(null, (set) => {
    console.warn('new subscription for', path);
    // console.log('writable for docRef:', docRef);
    unsubscribe = onSnapshot(docRef, (snapshot) => {
      // console.log('snapshot', snapshot);
      // console.log('snapshot.data()', snapshot.data());
      set((snapshot.data() as T) ?? null);
    });

    return () => unsubscribe();
  });

  return {
    subscribe,
    ref: docRef,
    id: docRef.id
  };
}

export const isLoggedIn = derived(user, ($user) => {
  // console.log('$user', $user);
  return !!$user;
});

export const userDoc: Readable<UserDoc | null> = derived(user, ($user, set) => {
  // console.log('userDoc: $user', $user);
  if ($user) {
    // console.log(`docStore(): users/${$user.uid}`);
    return docStore<UserDoc>(`users/${$user.uid}`).subscribe(set);
  } else {
    set(null);
  }
});

export const setUserDoc = derived(user, ($user) => {
  return async (data: UserDoc) => {
    // Check if a valid uid is available in $user.data
    if (!($user && $user.uid)) {
      throw new Error('cant update: user id not found');
    }
    const docRef: DocumentReference<UserDoc> = doc(db, `users/${$user.uid}`);
    return await setDoc(docRef, data, { merge: true });
  };
});

export const setUserDocByUid = async (uid: string, data: UserDoc) => {
  // Check if a valid uid is available in $user.data
  if (!uid) {
    throw new Error('cant update: user id not found');
  }
  const docRef: DocumentReference<UserDoc> = doc(db, `users/${uid}`);
  return await setDoc(docRef, data, { merge: true });
};

export const getUserDocByEmail = async (email: string): Promise<UserDoc | undefined> => {
  if (!email) {
    throw new Error('No email');
  }

  const usersRef = collection(db, 'users');
  const q = query(usersRef, where('email', '==', email), limit(1));
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    const tcQ = query(usersRef, where('tc_email', '==', email), limit(1));
    const tcQuerySnapshot = await getDocs(tcQ);

    if (tcQuerySnapshot.empty) return undefined;
    return tcQuerySnapshot.docs[0].data() as UserDoc;
  }

  return querySnapshot.docs[0].data() as UserDoc;
};

const CONFETTIES_COL = 'confetties';

export const addConfettieDoc = derived([user], ([$user]) => {
  return async (confettiObject: ConfettiDoc) => {
    // Check if a valid uid is available in $user.data
    if (!$user?.uid) {
      throw new Error('cant update: user id not found');
    }
    // if user is logged in, add confetti to collection
    const colRef = collection(db, CONFETTIES_COL);

    // firebase rules: The object must include ownerUid
    const savedConfettiObject: ConfettiDoc = {
      ...confettiObject,
      ownerUid: $user.uid,
      updated: serverTimestamp(),
      created: serverTimestamp()
    };
    console.log({ savedConfettiObject });
    return await addDoc(colRef, savedConfettiObject);
  };
});

export const setConfettiDoc = derived(user, ($user) => {
  return async (confettiDocId: string, data: ConfettiDoc) => {
    try {
      // Check if a valid uid is available in $user.data
      if (!$user?.uid) {
        throw new Error(`cant update confetti id (${confettiDocId}): user id not found`);
      }
      const newData: ConfettiDoc = {
        ...data,
        updated: serverTimestamp()
      };
      const docRef: DocumentReference<ConfettiDoc | DocumentData> = doc(
        db,
        `${CONFETTIES_COL}/${confettiDocId}`
      );
      return await setDoc(docRef, newData, { merge: true });
    } catch (error) {
      console.error(error, confettiDocId, data);
    }
  };
});

export const confettiDocs: Readable<ConfettiDoc[] | null> = derived(user, ($user, set) => {
  let unsubscribe: Unsubscribe | null = null;

  // Check if a valid uid is available in $user.data
  if ($user?.uid) {
    const colRef = collection(db, CONFETTIES_COL);
    const q = query(colRef, where('ownerUid', '==', $user.uid), orderBy('created'));

    // Subscribe to the user document and update the store whenever data changes
    unsubscribe = onSnapshot(q, (snapshot) => {
      const documents: ConfettiDoc[] = [];
      snapshot.forEach((doc) => {
        const data = doc.data() as ConfettiDoc;
        data.id = doc.id; // Add the ID to the document object
        documents.push(data);
      });

      set(documents.reverse());
    });
  } else {
    set(null);
  }

  // Return a function to unsubscribe from the previous subscription
  return () => {
    if (unsubscribe) {
      unsubscribe();
    }
  };
});

export const generalStatsDoc = docStore<GeneralStatsDoc>(`stats/general-stats`);
export const gameStatsDoc = docStore<GameStatsDoc>(`stats/game-stats`);
