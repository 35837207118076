// import enc_utf8 from 'crypto-js/enc-utf8';
// import AES from 'crypto-js/AES';

import pkg from 'crypto-js';
const { AES, enc } = pkg;

// this is for better complexity in here.js
const encrypt = AES.encrypt;
const decrypt = AES.decrypt;
const enc_utf8 = enc.Utf8;

const secretKey = 'c0%nu!zibPRdvl';

export function encodeSecretObject(objectToEncode: object, key = secretKey): string {
  const encryptedObject = encrypt(JSON.stringify(objectToEncode), key);
  return encryptedObject.toString();
}

export function decodeSecretObject(encodedObject: string, key = secretKey): object {
  const decryptedBytes = decrypt(encodedObject, key);
  // console.log('decryptedBytes', decryptedBytes);
  const decryptedString = decryptedBytes.toString(enc_utf8);
  return JSON.parse(decryptedString);
}
