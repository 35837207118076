<script>
  import { onMount } from 'svelte';
    import LoadingSpinner from './LoadingSpinner.svelte';

  export let ms = 500;
  let shown = false;
  export let showLoading = false;

  onMount(() =>
    setTimeout(() => {
      // console.log('delayed!');
      shown = true;
    }, ms)
  );
</script>

{#if shown}
  <slot />
  {:else if showLoading}
  <LoadingSpinner />
{/if}
