<script lang="ts">
  import { ConfettiP5 } from 'svelte-confetti-p5';
  import { animationFlippingIntensities, type ConfettiAnimation } from '../ConfettiAnimation.types';
  import {
    getColoredParticlesImagesBase64URLs,
    responsiveParticleSize
  } from '../ConfettiAnimation.functions';
  import { minMaxBy1 } from '../minMaxBy1';

  export let animation: ConfettiAnimation;
  export let w: number;
  export let h: number;

  $: rainSizes = [
    minMaxBy1(animation?.size?.[0], 8.5, 4, 5),
    minMaxBy1(animation?.size?.[0], 8.5, 4, 7.5) * 2
  ];

  $: rainDensityMultiplier = minMaxBy1(animation?.density?.[0], 1.7, 0.3, 1, true, 2.05);

  $: minSize = responsiveParticleSize(rainSizes[0]);
  $: maxSize = responsiveParticleSize(rainSizes[1]);

  let images: string[] = [];
  $: {
    images =
      getColoredParticlesImagesBase64URLs(animation.particles, animation.pallete, animation.size) ||
      [];
  }

  const AnimationFlippingIntensitiesMap: Record<
    (typeof animationFlippingIntensities)[number],
    number
  > = {
    no: 0,
    low: 4,
    high: 20
  };

  $: flip = AnimationFlippingIntensitiesMap[animation.flippingIntensity || 'high'];

  $: weight = animation.fallingSpeed === 'slow' ? 30 : 100;
  $: power = animation.fallingSpeed === 'slow' ? 3 : 10;
</script>

<!-- <h2 class="text-4xl p-3">{JSON.stringify(flip)}</h2> -->
<div class="confetti-p5-container">
  <!-- {#if confettiObject.animation.duration === 'shorter'}
  <ConfettiP5
    {w}
    {h}
    position="absolute"
    colors={confettiObject.animation.pallete}
    amount={80}
    {power}
    spacing={2}
    weight={140}
    flip={10}
  />
{/if} -->
  {#if animation.duration === 'short'}
    <ConfettiP5
      {w}
      {h}
      position="absolute"
      colors={animation.pallete}
      amount={120 * rainDensityMultiplier}
      power={power * 1.5}
      spacing={4}
      {minSize}
      {maxSize}
      shapes={images?.length ? 'images' : 'circles'}
      {images}
      {weight}
      {flip}
    />
  {/if}
  {#if animation.duration === 'medium'}
    <ConfettiP5
      {w}
      {h}
      position="absolute"
      colors={animation.pallete}
      amount={120 * rainDensityMultiplier}
      {power}
      {minSize}
      {maxSize}
      shapes={images?.length ? 'images' : 'circles'}
      {images}
      {weight}
      {flip}
    />
  {/if}
  {#if animation.duration === 'long'}
    <ConfettiP5
      {w}
      {h}
      position="absolute"
      colors={animation.pallete}
      {power}
      spacing={50}
      amount={420 * rainDensityMultiplier}
      {minSize}
      {maxSize}
      shapes={images?.length ? 'images' : 'circles'}
      {images}
      {weight}
      {flip}
    />
  {/if}
  {#if animation.duration === 'infinite'}
    <ConfettiP5
      {w}
      {h}
      amount={100 * rainDensityMultiplier}
      position="absolute"
      colors={animation.pallete}
      {power}
      loop
      {minSize}
      {maxSize}
      shapes={images?.length ? 'images' : 'circles'}
      {images}
      {weight}
      {flip}
    />
  {/if}
</div>
