import { browser } from '$app/environment';
import { env_confetti_domain } from '../helpers/confetti_env';
import { wait } from '../helpers/wait';

if (browser) {
  if (!window['ConfettiPage']) {
    window['ConfettiPage'] = {
      play: () => {
        // Plays all confettipage registered
        if (!window['ConfettiPage'].playFunctions.length)
          throw new Error('ConfettiPage: No playFunctions found');

        window['ConfettiPage'].playFunctions.forEach((playFunc) => playFunc());
      },
      // Confetti Registrations
      playFunctions: []
    };
  }
}

export function add_play_function_to_window(playFunc: Function) {
  // Registers confetti animation
  window['ConfettiPage'].playFunctions.push(playFunc);
}

export async function writeConfettiPlay(avoidPlayAPI: any, confettiId: any, playedInURL: any) {
  await wait(1000);
  if (avoidPlayAPI) {
    console.log({ avoidPlayAPI });
    return;
  }
  if (confettiId && playedInURL) {
    const playData = { confettiId, playedInURL };
    console.log('playData', playData);

    const res = await fetch(`${env_confetti_domain}/api/play`, {
      method: 'POST',
      body: JSON.stringify(playData),
      headers: {
        'content-type': 'application/json'
      }
    });
    console.log('res', res);
    const playResData = await res.json();
    console.log('playResData', playResData);
  }
}
