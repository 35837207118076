/**
 * @returns 'he' | 'en' and so on.
 * converts 'he-IL' to 'he';
 */
export function get_html_language_code(default_language_code: string) {
  const language_code = document.documentElement?.lang?.split('-')[0]?.toLowerCase();
  if (language_code.length === 2) {
    return language_code;
  }
  return default_language_code;
}


