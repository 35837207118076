<script lang="ts">
  import { decodeSecretObject } from '../../exports/secret-coder';
  import Confetties from './Confetties.svelte';
  import type { ConfettiDoc } from '../db/ConfettiDoc';
  import DomainWarningPopup from './DomainWarningPopup.svelte';
  import { getDomain, isCurrentWebsite } from '../helpers/isCurrentWebsite';
  import { browser, dev } from '$app/environment';
  import { onMount, tick } from 'svelte';
  import InvalidWarningPopup from './InvalidWarningPopup.svelte';
  import { confetti_env_local, env_confetti_domain } from '../helpers/confetti_env';
  import { isLangRtl } from '../i18n/inlang_rtl';
  import { get_html_language_code } from '../helpers/getHtmlLanguageCode';
  import { wait } from '../helpers/wait';
  import type { confettiTrigger } from './ConfettiCode.types';
  import { add_play_function_to_window, writeConfettiPlay } from './ConfettiCode.functions';

  export let confetticode: string;
  export let trigger: confettiTrigger = 'load';

  let confettiObject: ConfettiDoc | undefined;
  let invalidConfetti = false;
  let play = false;

  onMount(() => {
    // Autoplay if loaded || trigger not set
    if (trigger === 'load' || !trigger) play = true;

    // <script data-trigger="custom"> will trigger when
    // window.ConfettiPage.play();
    if (trigger === 'custom')
      add_play_function_to_window(async () => {
        play = false;
        await tick();
        play = true;
      });
  });

  try {
    confettiObject = decodeSecretObject(confetticode);
    console.log({ confettiObject });
  } catch (error) {
    invalidConfetti = true;
    console.error(
      `ConfettiPage.com: ConfettiCode is invalid. Please copy it again from ${env_confetti_domain}`
    );
  }

  $: showDomainWarning =
    (browser && confettiObject?.site && !isCurrentWebsite(confettiObject?.site)) ||
    !confettiObject?.site;

  $: {
    if (showDomainWarning) {
      console.warn('ConfettiPage.com: The confetti code is not linked to this domain.');
    }
  }

  $: avoidPlayAPI = showDomainWarning || invalidConfetti;

  // Confetti will ignore warnings for all *.mykajabi.com websites.
  // Otherwise, it will show the domain warning in the kajabi editor.
  const domainsWithHiddenDomainWarning = browser
    ? window?.location.hostname.includes('.mykajabi.com')
    : false;

  let hideDomainWarning = domainsWithHiddenDomainWarning;

  if (hideDomainWarning) console.log('ignoreWarningsHere=true', window.location.hostname);

  const confettiId = confettiObject?.id;
  const playedInURL = browser
    ? confetti_env_local
      ? confettiObject?.site
      : window.location.href
    : '';

  let lang: string = 'en';
  let rtl: boolean;

  onMount(async () => {
    lang = get_html_language_code(lang);
    if (lang) rtl = isLangRtl(lang);
  });

  $: {
    if (play) {
      writeConfettiPlay(avoidPlayAPI, confettiId, playedInURL);
    }
  }
</script>

{#if play}
  {#if !invalidConfetti}
    <Confetties {confettiObject} {rtl} {lang} />
  {/if}

  {#if invalidConfetti}
    <InvalidWarningPopup {rtl} {lang} />
  {:else if showDomainWarning && !hideDomainWarning}
    <DomainWarningPopup {confettiObject} {rtl} {lang} />
  {/if}
{/if}
