<script lang="ts">
  import { env_confetti_domain } from '../helpers/confetti_env';
  import WarningPopup from './WarningPopup.svelte';
  export let lang: string;
  export let rtl: boolean;
</script>

<WarningPopup
  title="The Installed Confetti Code Is Invalid"
  p="Please remove this confetti code and try to install it again. If you still encounter problems, reach out to the ConfettiPage support."
  href="{env_confetti_domain}/{lang}/mine"
  buttonText="Get a working Confetti Code here"
  buttonSubText={`Go to your desired confetti and copy the code again`}
/>
