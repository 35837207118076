<script lang="ts">
  import { fade, fly, scale } from 'svelte/transition';
  import { onMount } from 'svelte';
  import { env_confetti_domain } from '../helpers/confetti_env';

  export let title: string;
  export let titleWithStrong: string = '';
  export let strong: string = '';
  export let p: string;
  export let href: string;
  export let buttonText: string;
  export let buttonSubText: string;

  let show = false;
  onMount(() => {
    const timeout = setTimeout(() => {
      show = true;
    }, 1000);
    return () => clearTimeout(timeout);
  });
</script>

{#if show}
  <div class="cp-dwp--popup">
    <div transition:scale={{ start: 0.95, duration: 300 }}>
      <button on:click={() => (show = false)} class="cp-dwp--exit">
        <svg width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.34 10.34">
          <rect
            x="4.72"
            y="-1.69"
            width=".91"
            height="13.72"
            transform="translate(-2.14 5.17) rotate(-45)"
          />
          <rect
            x="-1.69"
            y="4.72"
            width="13.72"
            height=".91"
            transform="translate(-2.14 5.17) rotate(-45)"
          />
        </svg>
      </button>
      <a class="cp-dwp--confettipage" href={env_confetti_domain} target="_blank">ConfettiPage.com</a
      >
      <div class="cp-dwp--title">
        {#if strong}
          {titleWithStrong}
        {:else}
          {title}
        {/if}
      </div>
      {#if strong}
        <div class="cp-dwp--site">{strong}</div>
      {/if}
      <div class="cp-dwp--p">
        {p}
      </div>

      <a class="cp-dwp--button" target="_blank" {href}>
        <div class="big">{buttonText}</div>
        <div class="small">{buttonSubText}</div>
      </a>
    </div>
  </div>
{/if}

{@html `
  <style lang="scss">
    .cp-dwp--popup * {
      text-decoration: none !important;
      border: none !important;
      background: none !important;
      line-height: 1.15 !important;
      box-sizing: border-box !important;
      color:inherit !important;
    }
    .cp-dwp--popup {
      position: fixed;
      display: flex;
      top:0;
      left:0;
      align-items: center;
      justify-content: center;
      font-size: 0.85rem;
      @media screen and (min-width: 768px) {
        font-size: 1rem;
      }
      width: 100%;
      height: 100%;
      overflow: hidden;
      overflow-y: auto;
      font-family: 'FtMonopol-WEB', Arial, Helvetica, sans-serif;
      z-index: 2147483646 !important;
      > div {
        z-index: 2147483646 !important;
        background: white !important;
        padding: 3em;
        max-width: 35em;
        border-radius: 3em;
        margin: 1em;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
        text-align: center;
        position: relative;
        .cp-dwp--exit {
          position: absolute;
          right: 0;
          top: 0;
          opacity: 0.3;
          padding: 2em;
          z-index: 9;
          &:hover {
            opacity: 1;
          }
        }
        .cp-dwp--confettipage {
          display: inline-block;
          margin-bottom: 2em;
          font-weight: bold;
          opacity: 0.5;
          &:hover {
            opacity:1;
          }
        }
        .cp-dwp--title {
          font-weight: 700;
          font-size: 1.8em;
        }
        .cp-dwp--p {
          margin-top:1em;
        }
        .cp-dwp--site {
          display: inline-flex;
          position: relative;
          padding: 0.25em 0.5em;
          border-radius: 0.375em;
          font-weight: 500;
          font-size: 1.25em;
          color: #4b5563 !important;
          background-color: #e5e7eb !important;
          margin-top: 0.15em;
        }
        .cp-dwp--button {
          margin-top: 1em;
          display: inline-block;
          width: 100%;
          border-radius: 2rem;
          background-color: black !important;
          padding: 1.25em 2em;
          text-align: center;
          font-size: 1.5em;
          font-weight: 700;
          color: white !important;
          &:hover {
            opacity:0.9;
          }
          > .small {
            font-weight: 400;
            margin-top: 0.5em;
            font-size: 0.6em;
          }
        }
      }
    }
  </style>`}
