import type { AnimationParticle } from './ConfettiAnimation.types';

export function emojiToSVG(emoji: string) {
  return `<svg width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg"><text x="5" y="5" font-family="'Arial Unicode MS', sans-serif" font-size="9" text-anchor="middle" dominant-baseline="middle">${emoji}</text></svg>`;
}

export const confettiParticles = {
  'circle-1': {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.56 4.56" width="5"><circle cx="2.28" cy="2.28" r="2.28" style="fill:#000000;"/></svg>',
    max: 8,
    min: 3
  },
  'circle-2': {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.56 4.56" width="9"><circle cx="2.28" cy="2.28" r="2.28" style="fill:#000000;"/></svg>',
    max: 11,
    min: 5
  },
  square: {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.47 11.47" width="10"><rect x="1.68" y="1.68" width="8.11" height="8.11" transform="translate(5.73 -2.37) rotate(45)" style="fill:#000000;"/></svg>',
    max: 17,
    min: 7
  },
  triangle: {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5.17 4.47" width="10"><polygon points="2.58 0 0 4.47 5.17 4.47 2.58 0 0 4.47 5.17 4.47 2.58 0" style="fill:#000000;"/></svg>',
    max: 19,
    min: 8
  },
  // apropo: {
  //   svg: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.41 5.57" width="10"><path d="m1.5.15L0,5.3c-.04.14.06.27.2.27h2.99c.14,0,.24-.14.2-.27L1.91.15c-.06-.2-.35-.2-.41,0L0,5.3c-.04.14.06.27.2.27h2.99c.14,0,.24-.14.2-.27L1.91.15c-.06-.2-.35-.2-.41,0Z" style="fill:#000000;"/></svg>`,
  //   max: 16,
  //   min: 6
  // },
  rhombus: {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.68 5.5" width="10"><polygon points="0 2.75 1.84 0 3.68 2.75 1.84 5.5 0 2.75" style="fill:#000000;"/></svg>',
    max: 16,
    min: 6
  },
  // 'line-1': {
  //   svg: '',
  //   max: 0,
  //   min: 0
  // },
  // 'line-2': {
  //   svg: '',
  //   max: 0,
  //   min: 0
  // },
  // 'line-3': {
  //   svg: '',
  //   max: 0,
  //   min: 0
  // },
  'sparkle-1': {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.45 6.45" width="10"><path d="m0,3.22c1.25,0,3.22-1.98,3.22-3.22,0,1.25,1.98,3.22,3.22,3.22-1.25,0-3.22,1.98-3.22,3.22,0-1.25-1.98-3.22-3.22-3.22Z" style="fill:#000000;"/></svg>',
    max: 16,
    min: 12
  },
  'sparkle-2': {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.36 9.36" width="10"><path d="m0,4.68c4.16,0,4.68-.52,4.68-4.68,0,4.16.52,4.68,4.68,4.68-4.16,0-4.68.52-4.68,4.68,0-4.16-.52-4.68-4.68-4.68Z" style="fill:#000000;"/></svg>',
    max: 35,
    min: 24
  },
  'star-1': {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.71 9.24" width="18"><polygon style="fill:#000000;" points="4.86 0 6 3.53 9.71 3.53 6.71 5.71 7.86 9.24 4.86 7.06 1.85 9.24 3 5.71 0 3.53 3.71 3.53 4.86 0"/></svg>',
    // svg: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 9.51" width="15"><polygon style="fill:#000000;" points="5 0 6.55 3.13 10 3.63 7.5 6.07 8.09 9.51 5 7.89 1.91 9.51 2.5 6.07 0 3.63 3.46 3.13 5 0"/></svg>`,
    max: 22,
    min: 11
  },
  'star-2': {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.75 6.5" width="10"><path d="m6.61,2.93l-1.21,1.09c-.11.1-.16.24-.13.38l.28,1.61c.06.35-.31.6-.62.43l-1.41-.82c-.12-.07-.27-.07-.4,0l-1.45.76c-.31.16-.67-.11-.6-.45l.34-1.6c.03-.14-.01-.28-.12-.38L.13,2.8c-.25-.24-.1-.67.25-.71l1.62-.17c.14-.01.27-.1.33-.23L3.05.23c.16-.31.61-.31.75.02l.66,1.49c.06.13.18.22.32.24l1.62.24c.35.05.48.48.22.72Z" style="fill:#000000;"/></svg>',
    max: 19,
    min: 9
  },
  'sparkle-3': {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.83 7.83" width="10"><path d="m7.83,3.91c-1.5-.15-2.38-.27-2.93-.66l1.21-1.42-1.52,1.12c-.42-.53-.55-1.41-.68-2.95-.13,1.54-.26,2.41-.68,2.95l-1.52-1.12,1.21,1.42c-.54.39-1.43.52-2.93.66,1.5.15,2.38.27,2.93.66l-1.21,1.42,1.52-1.12c.42.53.55,1.41.68,2.95.13-1.54.26-2.41.68-2.95l1.52,1.12-1.21-1.42c.54-.39,1.43-.52,2.93-.66Z" style="fill:#000000;"/></svg>',
    max: 30,
    min: 9
  },
  'paper-piece-1': {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.73 6.17" width="10"><polygon points="6.73 .34 5.39 6.17 0 4.14 .82 0 6.73 .34" style="fill:#000000;"/></svg>',
    max: 11,
    min: 5
  },
  // 'paper-piece-2': {
  //   svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.77 4.58" width="10"><polygon points="1.61 4.58 0 .21 6.19 0 6.77 2.95 1.61 4.58" style="fill:#000000;"/></svg>',
  //   max: 13,
  //   min: 6
  // },
  'long-paper-piece-1': {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.17 10.84" width="10"><path d="m0,3.6L2.99,0s4.64,5.7,9.18,8.43l-2.39,2.42S5.39,8.96,0,3.6Z" style="fill:#000000;"/></svg>',
    max: 18,
    min: 8
  },
  'long-paper-piece-2': {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.13 14.43" width="10"><polygon points="0 1.43 4.07 0 8.13 13.58 5.8 14.43 0 1.43" style="fill:#000000;"/></svg>',
    max: 15,
    min: 6
  },
  semicircle: {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.81 5.46" width="10"><path d="m0,4.02L1.76,0s5.52,4.4,11.69.66l.36,1.96s-3.91,5.31-13.81,1.4Z" style="fill:#000000;"/></svg>',
    max: 25,
    min: 10
  },
  tilda: {
    svg: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.11 4.39" width="10"><path d="m1.07,0s.57,1.79,2.81,1.59,4.34-2.52,6.24-.03l-1.18,2.75c-1.83-2.67-4.03.2-6.07.07S0,2.69,0,2.69L1.07,0Z" style="fill:#000000;"/></svg>`,
    max: 25,
    min: 10
  },
  bisli: {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.41 8.01" width="10"><path d="m8.48,2.96c-.8.62-1.59.45-2.11.33-.45-.1-.54-.1-.68,0s-.15.19-.17.65c-.02.54-.04,1.35-.83,1.97s-1.59.45-2.11.33c-.45-.1-.54-.1-.67,0s-.15.19-.17.65v1.09s-1.72.02-1.72.02v-1.08c0-.61.03-1.42.82-2.04s1.59-.45,2.11-.33c.45.1.54.1.67,0s.15-.19.17-.65c.02-.54.04-1.35.83-1.97s1.59-.45,2.12-.33c.45.1.54.1.68,0,.09-.07.14-.13.16-.47l.11-1.12,1.73.18-.11,1.08c-.01.3-.06,1.1-.82,1.7Z" style="fill:#000000;"/></svg>',
    max: 22,
    min: 8
  },
  'zig-zag-1': {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.56 8.26" width="10"><path d="m.86,0s2.88.59,6.25,2.14l-1.6,2.25s1.54.38,3.05,1.72l-.54,2.15s-2.7-1.85-5.92-2.44l1.27-2.51s-2.29-.62-3.38-.53L.86,0Z" style="fill:#000000;"/></svg>',
    max: 23,
    min: 9
  },
  // 'zig-zag-2': {
  //   svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.33 9.71" width="10"><path d="m6.64,5.89l-3.16,2.78L0,6.26,6.7,1.16l2.88,3.5.23-.33c1.61-2.24,3.9-3.83,4.66-4.33l1.85,3.12c-2.29,1.68-4.31,3.84-6.15,6.58l-3.53-3.82Z" style="fill:#000000;"/></svg>',
  //   max: 29,
  //   min: 9
  // },
  // 'x-1': {
  //   svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.48 11.8" width="10"><polygon points="3.02 10.91 9 0 12.08 1.49 5.76 11.8 3.02 10.91" style="fill:#000000;"/><path d="m13.48,8.91C7.14,8.51,1.72,6.1,0,4.83L2.96,1.57c4.98,3.7,8.71,4.24,10.46,4.42l.06,2.91Z" style="fill:#000000;"/></svg>',
  //   max: 24,
  //   min: 8
  // },
  'sprinkle-1': {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.5 3.08" width="10"><rect x="0" y="0" width="13.5" height="3.08" rx="1.54" ry="1.54" style="fill:#000000;"/></svg>',
    max: 25,
    min: 12
  },
  // 'duck': {
  //   svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.84 4.84" width="10"><g id="rS22K3"><path d="m2.94,2.38c.12,0,.25,0,.37,0,.3-.01.57-.11.8-.31.06-.05.11-.11.16-.18.05-.08.14-.09.2-.02.13.14.22.31.28.48.09.25.12.51.08.77-.03.22-.09.43-.19.64-.09.18-.22.34-.37.47-.17.15-.37.26-.58.36-.18.08-.37.13-.56.17-.3.06-.6.08-.9.07-.3,0-.59-.05-.87-.14-.2-.06-.4-.14-.58-.26-.2-.12-.35-.28-.47-.48-.1-.18-.15-.38-.14-.59,0-.22.05-.42.16-.62.08-.14.17-.27.29-.38.04-.04.09-.09.14-.13.1-.09.11-.23.04-.33-.06-.08-.13-.15-.22-.2-.15-.09-.28-.2-.4-.33-.06-.07-.12-.15-.16-.24-.02-.05,0-.09.05-.1.02,0,.04,0,.06,0,.13-.01.26-.03.4-.06.03,0,.05-.02.06-.06C.68.48,1.03.13,1.48.03c.42-.09.79.02,1.1.32.17.17.28.38.33.62.07.4-.03.75-.31,1.04-.04.04-.08.07-.12.11-.05.05-.08.11-.07.18.01.06.04.08.1.08.1,0,.19,0,.29,0,.04,0,.08,0,.13,0,0,0,0,0,0,0ZM1.16.78c-.11,0-.21.09-.21.2,0,.11.09.2.2.2.11,0,.2-.09.2-.2,0-.11-.09-.2-.2-.21Z" style="fill:#000000;"/></g></svg>',
  //   max: 25,
  //   min: 14
  // },
  // 'bamba-1': {
  //   svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.37 5.35" width="10"><path d="m0,2.04v-.04C.09.55,1.82-.42,3.14.21c1.6.76,3.48,1.1,6.18-.04,1.35-.57,3,.46,3.04,1.93h0c.02.83-.34,1.58-1.44,2.11-1.94.94-6.4,2.1-9.88-.18C.38,3.58-.04,2.85,0,2.04Z" style="fill:#000000;"/></svg>',
  //   max: 24,
  //   min: 15
  // },
  'heart-1': {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.01 5.46" width="10"><path d="m7.01,1.38v.08c0,1.13-.69,1.74-2.45,3.16-.27.22-.57.46-.89.72-.06.05-.11.09-.16.12-.05-.03-.1-.07-.16-.12-.33-.26-.63-.51-.9-.73C.69,3.2,0,2.58,0,1.46v-.08C.02.64.79,0,1.54,0h.02c.83,0,1.53.72,1.95,1.43h0C3.93.72,4.63,0,5.45,0h.02c.76,0,1.53.64,1.54,1.38Z" style="fill:#000000;"/></svg>',
    max: 18,
    min: 9
  },
  // 'hollow-heart-1': {
  //   svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.22 6.82" width="10"><path d="m4.12,6.82C2.48,5.47-.16,3.94,0,2.06.09-.02,2.73-.72,4.11.88,5.48-.72,8.13-.02,8.22,2.02c.01,1.41-.76,2.16-2.41,3.46-.68.55-1.34,1.06-1.69,1.33ZM2.19.97c-.6,0-1.18.52-1.18,1.05-.25,1.02,1.76,2.5,3.11,3.53.32-.25.82-.64,1.06-.84,1.68-1.33,2.04-1.79,2.04-2.68,0-.53-.58-1.05-1.18-1.05-.97.06-1.43.88-1.92,1.47-.49-.59-.97-1.41-1.93-1.47Z" style="fill:#000000;"/></svg>',
  //   max: 24,
  //   min: 15
  // },
  'cards-heart': {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2.37 2.1" width="10"><path d="m1.72,0c-.27,0-.5.25-.53.36-.03-.11-.25-.36-.53-.36-.39,0-.66.29-.66.64,0,.58.9.83,1.19,1.46.37-.64,1.19-.89,1.19-1.46,0-.35-.27-.64-.65-.64Z" style="fill:#000000;"/></svg>',
    max: 21,
    min: 13
  },
  'cards-diamonds': {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2.19 3.06" width="10"><path d="m2.19,1.5h-.05s-.01-.01-.02-.02C1.64,1.17,1.3.67,1.09,0c0,0,0,0,0,0,0,0,0,0,0,0C.88.69.53,1.21.02,1.52c0,0-.01.01-.02.02,0,0,0,0,0,0h.13H0c.53.3.89.83,1.12,1.52v-.02s0,0,0,0v.02s0,0,0,0h0c.2-.68.53-1.2,1.02-1.52h.05s-.01-.01-.02-.02c0,0,0,0,0,0,0,0,0,0,0,0,0,0,.01-.01.02-.02h0s0,0,0,0h0Z" style="fill:#000000;"/></svg>',
    max: 18,
    min: 12
  },
  'cards-clubs': {
    // svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2.21 2.62" width="10"><path d="m1.59,1.11c-.06,0-.11.02-.16.03.18-.11.3-.3.3-.52,0-.34-.28-.62-.62-.62S.48.28.48.62c0,.22.12.41.3.52-.05-.01-.1-.03-.16-.03-.34,0-.62.28-.62.62s.28.62.62.62c.18,0,.34-.08.45-.2l-.12.21-.16.26h.3s.3,0,.3,0l-.16-.26-.13-.23c.11.13.28.22.46.22.34,0,.62-.28.62-.62s-.28-.62-.62-.62Z" style="fill:#000000;"/></svg>',
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2.86 2.57" width="10"><path d="m2.32,1.13c-.2-.03-.42.04-.59.15v-.02c.19-.15.32-.41.32-.64,0-.34-.28-.62-.62-.62h0c-.34,0-.62.28-.62.62,0,.23.13.49.32.64v.02c-.17-.12-.4-.18-.59-.15C.2,1.18-.04,1.49,0,1.83c.05.34.36.58.7.53.3-.04.62-.32.65-.62h.01c-.06.32-.12.59-.25.83h.3s0-.01,0-.01h.3c-.13-.22-.19-.49-.25-.81h.01c.04.3.35.57.65.62.34.05.65-.19.7-.53.05-.34-.19-.65-.53-.7Z" style="fill:#000000;"/></svg>',
    max: 25,
    min: 12
  },
  'cards-spades': {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2.43 2.61" width="10"><path d="m1.21,0C.84.71,0,1,0,1.63c0,.39.32.71.71.71.17,0,.33-.07.45-.17l-.1.18-.16.26h.3s.3,0,.3,0l-.16-.26-.11-.19c.13.11.29.18.47.18.39,0,.71-.32.71-.71,0-.65-.92-.92-1.21-1.63Z" style="fill:#000000;"/></svg>',
    max: 20,
    min: 13
  },
  'flower-1': {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.23 3.22" width="10"><path d="m3.15.96c-.25-.48-.7-.57-1.02-.15-.02.03-.05.06-.07.09.01-.04.03-.09.04-.13C2.24.36,1.97-.08,1.44.01c-.54.09-.76.49-.45.92.02.03.04.06.06.09-.04-.03-.07-.05-.11-.08C.59.69.09.81.01,1.34c-.08.54.23.88.74.72.03-.01.07-.02.11-.03-.04.03-.07.05-.11.08-.35.26-.4.76.09,1.01.48.24.91.05.91-.48,0-.04,0-.07,0-.11.01.04.03.09.04.12.14.42.61.61.99.23.38-.39.33-.85-.18-1.01-.03-.01-.07-.02-.11-.04.05,0,.09,0,.13,0,.44,0,.77-.39.52-.87Zm-1.5,1.06c-.2,0-.36-.16-.36-.36s.16-.36.36-.36.36.16.36.36-.16.36-.36.36Z" style="fill:#000000;"/></svg>',
    // svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.32 3.17" width="10"><path d="m3.32,1.19s-.64-.42-1.1-.22C2.29.49,1.64-.01,1.65,0c0,0-.59.48-.54.97C.66.76-.01,1.22,0,1.22c0,0,.27.71.76.82-.34.36-.11,1.14-.11,1.13,0,0,.76-.04,1.01-.47.24.43,1.05.46,1.04.45,0,0,.2-.74-.13-1.11.48-.09.76-.86.75-.85Zm-1.22.61c-.08.56-.96.45-.87-.12.08-.56.93-.44.87.12Z" style="fill:#000000;"/></svg>',
    max: 25,
    min: 14
  },
  'flower-2': {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.62 3.52" width="10"><g id="_Radial_Repeat_"><path d="m2.1,3.24c-.17-.26-.32-1.37-.32-1.37,0,0,1,.52,1.16.78s.16.57-.12.77-.57.08-.73-.18Z" style="fill:#000000;"/></g><g id="_Radial_Repeat_-2"><path d="m.59,2.59c.19-.24,1.2-.72,1.2-.72,0,0-.19,1.11-.38,1.35s-.49.33-.77.13-.25-.51-.05-.75Z" style="fill:#000000;"/></g><g id="_Radial_Repeat_-3"><path d="m.73.95c.29.11,1.06.92,1.06.92,0,0-1.11.16-1.4.05S-.08,1.55.03,1.23s.41-.39.7-.28Z" style="fill:#000000;"/></g><g id="_Radial_Repeat_-4"><path d="m2.34.58c-.02.31-.55,1.29-.55,1.29,0,0-.5-1.01-.48-1.32S1.52,0,1.86,0s.5.27.49.58Z" style="fill:#000000;"/></g><g id="_Radial_Repeat_-5"><path d="m3.19,2c-.3.08-1.4-.12-1.4-.12,0,0,.81-.79,1.1-.87s.6.02.7.35-.1.56-.4.64Z" style="fill:#000000;"/></g></svg>',
    // svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3.32 3.17" width="10"><path d="m3.32,1.19s-.64-.42-1.1-.22C2.29.49,1.64-.01,1.65,0c0,0-.59.48-.54.97C.66.76-.01,1.22,0,1.22c0,0,.27.71.76.82-.34.36-.11,1.14-.11,1.13,0,0,.76-.04,1.01-.47.24.43,1.05.46,1.04.45,0,0,.2-.74-.13-1.11.48-.09.76-.86.75-.85Zm-1.22.61c-.08.56-.96.45-.87-.12.08-.56.93-.44.87.12Z" style="fill:#000000;"/></svg>',
    max: 20,
    min: 12
  },
  // 'flower-2': {
  //   svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2.27 2.47" width="10"><path d="m1.9,1.29c.68-.34.34-1.05-.34-.69.05-.76-.74-.81-.76-.05C.16.12-.28.78.37,1.18c-.68.34-.34,1.05.34.69-.05.76.74.81.76.05.63.42,1.08-.23.43-.64Zm-.5.23c-.35.34-.9-.23-.53-.56.35-.34.9.23.53.56Z" style="fill:#000000;"/></svg>',
  //   max: 18,
  //   min: 14
  // },
  // leaf: {
  //   svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5.95 5.54" width="10"><path d="m1.28,3.7c.6-.65,1.3-1.3,1.83-1.46-.88.53-1.43,1.62-1.54,1.85,3.13,1.23,2.71-2.79,4.38-4.1C4.88.06-.49.44,1.28,3.7Z" style="fill:#000000;"/><path d="m1.48,5.54s.53-2.14,1.88-3.3c-1.69.84-2.46,2.96-2.46,2.96" style="fill:#000000;"/></svg>',
  //   max: 22,
  //   min: 13
  // },
  'snowflake-1': {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.57 12.06" width="10"><g id="_Radial_Repeat_"><path d="m10.5,9.05c-.09.15-.25.24-.41.24-.09,0-.17-.02-.25-.06l-1.34-.77c0,.29.11.63.31,1,.13.23.05.53-.18.66-.08.04-.16.06-.24.06-.17,0-.33-.09-.42-.24-.4-.71-.52-1.4-.37-2l-1.83-1.06v2.12c.6.15,1.14.61,1.55,1.31.13.23.06.53-.17.66-.08.05-.16.07-.25.07-.16,0-.32-.08-.41-.24-.21-.36-.46-.61-.72-.74v1.52c0,.27-.22.48-.49.48s-.48-.21-.48-.48v-1.53c-.26.14-.51.39-.72.75-.14.23-.43.31-.66.17-.23-.13-.31-.43-.17-.66.41-.71.95-1.16,1.55-1.31v-2.13l-1.84,1.07c.16.6.03,1.29-.36,2-.09.15-.26.24-.43.24-.08,0-.16-.02-.23-.06-.24-.13-.32-.43-.19-.66.21-.36.31-.7.31-1l-1.34.77c-.07.04-.16.06-.24.06-.17,0-.33-.09-.42-.24-.13-.23-.05-.53.18-.66l1.34-.77c-.25-.16-.61-.24-1.03-.24-.27,0-.48-.23-.48-.49s.22-.43.49-.48c.82.01,1.49.25,1.91.69l1.84-1.06-1.84-1.07c-.43.44-1.1.68-1.91.69h-.01c-.26,0-.48-.22-.48-.48s.21-.49.48-.49c.42,0,.78-.08,1.03-.24L.24,3.68c-.23-.13-.31-.43-.18-.66s.43-.31.66-.18l1.34.77c0-.3-.1-.64-.31-1-.13-.24-.05-.53.19-.66.23-.13.52-.05.66.18.4.71.52,1.41.35,2l1.85,1.07v-2.14c-.6-.15-1.14-.6-1.55-1.3-.14-.23-.06-.53.17-.67.23-.13.52-.06.66.18.21.36.46.61.72.75V.49c0-.27.22-.49.48-.49s.49.22.49.49v1.52c.26-.13.51-.38.72-.74.13-.24.43-.31.66-.18.23.14.3.44.17.67-.41.69-.95,1.15-1.55,1.3v2.13l1.84-1.06c-.17-.59-.04-1.29.36-2,.13-.23.43-.31.66-.18s.31.42.18.66c-.2.36-.31.7-.31,1l1.34-.77c.23-.13.53-.05.66.18.14.23.06.53-.17.66l-1.34.77c.25.16.6.24,1.02.24.27,0,.49.22.48.49,0,.26-.22.48-.48.48-.82-.01-1.48-.25-1.91-.69l-1.85,1.07,1.84,1.06c.43-.44,1.09-.68,1.92-.69.26,0,.48.21.48.48.01.26-.21.48-.48.49-.42,0-.77.08-1.02.24l1.34.77c.23.13.31.43.17.66Z" style="fill:#000000;"/></g></svg>',
    max: 26,
    min: 14
  },
  'moon-1': {
    svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2.79 2.92" width="10"><path d="m2.67,2.16c-.55.31-1.24.12-1.55-.42-.31-.55-.12-1.24.42-1.55.11-.07.24-.1.36-.12-.38-.12-.8-.09-1.16.12C.03.59-.21,1.49.19,2.19c.4.7,1.3.94,2,.54.28-.16.48-.4.6-.67-.04.03-.07.08-.12.1Z" style="fill:#000000;"/></svg>',
    max: 28,
    min: 8
  },
  'hollow-circle-1': {
    svg: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.87 11.87" width="10"><path d="m11.79,4.99C11.27,1.75,8.22-.44,4.98.08,1.75.61-.45,3.66.08,6.89c.52,3.24,3.57,5.43,6.81,4.91,3.23-.53,5.43-3.58,4.9-6.81Zm-5.3,4.38c-1.89.31-3.68-.98-3.99-2.87-.31-1.89.98-3.68,2.87-3.99,1.89-.31,3.68.98,3.99,2.87.31,1.89-.98,3.69-2.87,3.99Z" style="fill:#000000;"/></svg>`,
    max: 17,
    min: 8
  },
  'hollow-circle-2': {
    svg: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.47 19.47" width="10"><path d="m19.34,8.18C18.48,2.86,13.48-.73,8.17.13,2.87.99-.74,6,.13,11.3c.85,5.31,5.86,8.91,11.17,8.05,5.3-.87,8.91-5.87,8.04-11.17Zm-8.51,8.27c-3.71.6-7.2-1.91-7.8-5.62-.62-3.71,1.91-7.2,5.62-7.81,3.71-.6,7.2,1.91,7.8,5.62.6,3.71-1.91,7.21-5.62,7.81Z" style="fill:#000000;"/></svg>`,
    max: 22,
    min: 12
  }
  // poop: {
  //   svg: emojiToSVG('💩'),
  //   max: 35,
  //   min: 14
  // },
  // grin: {
  //   svg: emojiToSVG('😁'),
  //   max: 35,
  //   min: 14
  // },
  // present: {
  //   svg: emojiToSVG('🎁'),
  //   max: 35,
  //   min: 14
  // }
};

export const confettiParticleGroups: {
  [key: string]: AnimationParticle[];
} = {
  clean: [],
  classic: [
    'circle-1',
    'circle-2',
    'zig-zag-1',
    // 'zig-zag-2',
    'paper-piece-1',
    // 'paper-piece-2',
    'long-paper-piece-1',
    'semicircle',
    'long-paper-piece-2',
    // 'x-1'
  ],
  stars: ['circle-1', 'star-1'],
  sparkles: ['circle-1', 'sparkle-2', 'sparkle-3'],
  love: ['heart-1', 'circle-1', 'circle-2', 'cards-heart'],
  cake: ['circle-1', 'circle-2', 'star-2', 'sprinkle-1'],
  bubbles: ['hollow-circle-1', 'hollow-circle-2'],
  garden: ['circle-1', 'flower-1', 'flower-2'],
  cards: ['cards-clubs', 'cards-diamonds', 'cards-heart', 'cards-spades'],
  snow: ['circle-1', 'snowflake-1']
};

export const confettiParticleGroupsKeys = Object.keys(confettiParticleGroups) as Array<
  keyof typeof confettiParticleGroups
>;
