import { confettiTriggers } from '../lib/ConfettiAnimation/ConfettiCode.types';
import ConfettiCode from '../lib/ConfettiAnimation/ConfettiCode.svelte';

const confettiScriptTags = 'script[data-confetticode]';

const scripts = document.querySelectorAll(confettiScriptTags);
const scriptsArray = [...scripts];
console.log('scripts found:', scriptsArray.length);
scriptsArray.forEach((script) => {
  const confetticode = script.getAttribute('data-confetticode');
  const trigger = script.getAttribute('data-trigger');
  const isValidTrigger = confettiTriggers.includes(trigger);

  try {
    if (script.getAttribute('data-status')) {
      throw 'confetti script initalized already, skipping';
    }

    if (!confetticode) throw 'confetti code not found';
    console.log('ConfettiPage.com: ConfettiCode Found:', confetticode);

    console.log('script before', script);

    // Confetti Code found, initailize code
    script.setAttribute('data-status', 'initialized');

    // Check for duplicates in the remaining scripts
    for (let i = scriptsArray.indexOf(script) + 1; i < scripts.length; i++) {
      let otherScript = scripts[i];
      if (otherScript.getAttribute('data-confetticode') === confetticode) {
        otherScript.setAttribute('data-status', 'duplicate-ignored');
      }
    }

    console.log('script after', script);

    const div = document.createElement('div');
    if (document.body) {
      document.body.append(div);
    } else {
      document.addEventListener('DOMContentLoaded', function (event) {
        document.body.append(div);
      });
    }

    // @ts-ignore
    const confettiCode = new ConfettiCode({
      target: div,
      props: {
        confetticode,
        ...(isValidTrigger ? { trigger } : {})
      }
    });
  } catch (error) {
    console.log(error);
  }
});
