export const DEG_TO_RAD = Math.PI / 180;
export const ROTATION_SPEED = 2;
export const MOVEMENT_SPEED = 1;
export const BOUNDARY = 20;
export const COLORS = [
    'hotpink',
    'gold',
    'dodgerblue',
    'tomato',
    'rebeccapurple',
    'lightgreen',
    'turquoise'
];
