<script lang="ts">
  import type { ConfettiAnimation } from '../ConfettiAnimation.types';
  import { random } from '../../vendor/svelte-canvas-confetti/utils/random';
  import { shuffle } from '../../helpers/shuffle';
  import ConfettiCannon from '../../vendor/svelte-canvas-confetti/ConfettiCannon.svelte';
  import Delayed from '../../components/elements/Delayed.svelte';
  import {
    responsiveParticleAmount,
    responsiveParticleSize,
    getColoredParticlesImages
  } from '../ConfettiAnimation.functions';
  import { minMaxBy1 } from '../minMaxBy1';

  export let animation: ConfettiAnimation;
  export let w: number;
  export let h: number;

  $: cannonDensity = minMaxBy1(animation?.density?.[0], 140, 20, 50);
  $: amount = responsiveParticleAmount(cannonDensity);

  $: cannonSizes = {
    w: () =>
      random(
        minMaxBy1(animation?.size?.[0], 10, 10, 18, true, 0.2),
        minMaxBy1(animation?.size?.[0], 16, 4, 10, true, 0.2)
      ),
    h: () =>
      random(
        minMaxBy1(animation?.size?.[0], 7, 4, 6, true, 0.2),
        minMaxBy1(animation?.size?.[0], 7, 3, 4, true, 0.2)
      )
  };

  $: sizeW = responsiveParticleSize(cannonSizes.w());
  $: sizeH = responsiveParticleSize(cannonSizes.h());
</script>

{#each shuffle(animation.positions || []) as pos, i}
  <Delayed ms={i === 0 ? 0 : random(i * 70, 600)}>
    {#if pos === 'tl'}
      <ConfettiCannon
        origin={[0, 0]}
        styles={getColoredParticlesImages(animation.particles, animation.pallete, animation.size)}
        angle={0}
        spread={90}
        force={35}
        particleCount={amount}
        onCreate={(p) => ({ ...p, w: sizeW, h: sizeH })}
      />
    {/if}
    {#if pos === 'tm'}
      <ConfettiCannon
        origin={[w / 2, 0]}
        styles={getColoredParticlesImages(animation.particles, animation.pallete, animation.size)}
        angle={90}
        spread={180}
        force={35}
        particleCount={amount}
        onCreate={(p) => ({ ...p, w: sizeW, h: sizeH })}
      />
    {/if}
    {#if pos === 'tr'}
      <ConfettiCannon
        origin={[w, 0]}
        styles={getColoredParticlesImages(animation.particles, animation.pallete, animation.size)}
        angle={180}
        spread={90}
        force={35}
        particleCount={amount}
        onCreate={(p) => ({ ...p, w: sizeW, h: sizeH })}
      />
    {/if}
    {#if pos === 'ml'}
      <ConfettiCannon
        origin={[0, h / 2]}
        styles={getColoredParticlesImages(animation.particles, animation.pallete, animation.size)}
        angle={0}
        spread={70}
        force={35}
        particleCount={amount}
        onCreate={(p) => ({ ...p, w: sizeW, h: sizeH })}
      />
    {/if}
    {#if pos === 'mm'}
      <ConfettiCannon
        origin={[w / 2, h / 2]}
        styles={getColoredParticlesImages(animation.particles, animation.pallete, animation.size)}
        angle={0}
        spread={360}
        force={35}
        particleCount={amount}
        onCreate={(p) => ({ ...p, w: sizeW, h: sizeH })}
      />
    {/if}
    {#if pos === 'mr'}
      <ConfettiCannon
        origin={[w, h / 2]}
        styles={getColoredParticlesImages(animation.particles, animation.pallete, animation.size)}
        angle={-180}
        spread={70}
        force={35}
        particleCount={amount}
        onCreate={(p) => ({ ...p, w: sizeW, h: sizeH })}
      />
    {/if}
    {#if pos === 'bm'}
      <ConfettiCannon
        origin={[w / 2, h]}
        styles={getColoredParticlesImages(animation.particles, animation.pallete, animation.size)}
        angle={-90}
        spread={180}
        force={35}
        particleCount={amount}
        onCreate={(p) => ({ ...p, w: sizeW, h: sizeH })}
      />
    {/if}
    {#if pos === 'bl'}
      <ConfettiCannon
        origin={[0, h]}
        styles={getColoredParticlesImages(animation.particles, animation.pallete, animation.size)}
        angle={0}
        spread={150}
        force={35}
        particleCount={amount}
        onCreate={(p) => ({ ...p, w: sizeW, h: sizeH })}
      />
    {/if}
    {#if pos === 'bm'}
      <ConfettiCannon
        origin={[w / 2, h]}
        styles={getColoredParticlesImages(animation.particles, animation.pallete, animation.size)}
        angle={-90}
        spread={180}
        force={35}
        particleCount={amount}
        onCreate={(p) => ({ ...p, w: sizeW, h: sizeH })}
      />
    {/if}
    {#if pos === 'br'}
      <ConfettiCannon
        origin={[w, h]}
        styles={getColoredParticlesImages(animation.particles, animation.pallete, animation.size)}
        angle={180}
        spread={150}
        force={35}
        particleCount={amount}
        onCreate={(p) => ({ ...p, w: sizeW, h: sizeH })}
      />
    {/if}
  </Delayed>
{/each}
