export function minMaxBy1(
  oneToZero: number | undefined,
  max: number,
  min: number,
  defaultValue: number,
  shouldEaseIn: boolean = false,
  power = 1.7
) {
  if (oneToZero === undefined) return defaultValue;
  if (shouldEaseIn) oneToZero = easeIn(oneToZero, power);
  //   if (max < min) {
  //     const _max = max;
  //     const _min = min;
  //     max = _min;
  //     min = _max;
  //   }
  return (max - min) * oneToZero + min;
}

function easeIn(t:number, power = 2) {
  // Ensure the input value t is between 0 and 1
  t = Math.max(0, Math.min(1, t));

  // Apply the ease-in function with the specified power
  return Math.pow(t, power);
}