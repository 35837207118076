<script lang="ts">
  import { browser } from '$app/environment';
  import { env_confetti_domain } from '../helpers/confetti_env';
  import type { ConfettiDoc } from '../db/ConfettiDoc';
  import { getDomain } from '../helpers/isCurrentWebsite';
  import WarningPopup from './WarningPopup.svelte';

  export let confettiObject: ConfettiDoc;
  export let rtl: boolean;
  export let lang: string;

  $: permitedSite = browser ? getDomain(confettiObject?.site || '') : '';
  $: currentDomain = browser ? getDomain(window.location.hostname) : '';
</script>

<WarningPopup
  title="The installed confetti animation code can't be used in this site"
  titleWithStrong="The installed confetti animation code can only be used on:"
  strong={permitedSite}
  p="Please remove this confetti code from this website and create a new one for free, specifically for your site."
  href="{env_confetti_domain}/{lang}/site?url={currentDomain}"
  buttonText="Create Confetti Animation"
  buttonSubText="Specifically for {currentDomain}"
/>
