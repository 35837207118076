import { derived, writable, type Readable, type Writable } from 'svelte/store';
import type { ConfettiDoc } from '../db/ConfettiDoc';
import { niceUrl, previewUrl, previewUrlInput } from '../../routes/(app)/start/urlStore';
import { user, userDoc } from '../db/firebase';
import type {
  AnimationAmount,
  AnimationDensity,
  AnimationDuration,
  AnimationFallingSpeed,
  AnimationFlippingIntensity,
  AnimationPallete,
  AnimationParticle,
  AnimationPosition,
  AnimationSize,
  AnimationSprayStyle,
  AnimationStyle,
  ConfettiAnimation
} from '$lib/ConfettiAnimation/ConfettiAnimation.types';

import { persist, createLocalStorage, type PersistentStore } from '@macfja/svelte-persistent-store';
import { env_confetti_domain } from '../helpers/confetti_env';
import { languageTag } from '../paraglide/runtime';

export const cameFromCreate = writable(false);
export const cameFromPreview = writable(false);

export const currentConfetti: Writable<ConfettiDoc> = writable();
export const persistedConfetti: PersistentStore<ConfettiDoc | null> = persist(
  writable(),
  createLocalStorage(),
  'persistedConfetti'
);

export function getNiceAffiliateLink(affiliateId: string | undefined) {
  return affiliateId && `${env_confetti_domain}/a/${affiliateId}`;
}
export function getRealAffiliateLink(affiliateId: string | undefined) {
  return affiliateId && `https://${affiliateId}--betterworld.thrivecart.com/confetti-page-pro/`;
}
export function appendCurrentAffiliateToUrl(affiliateId: string | undefined, url: string) {
  if (!affiliateId) return url;
  return getNiceAffiliateLink(affiliateId) + `?goto=${url.replace(env_confetti_domain, '')}`;
}
// export const appendCurrentAffiliateToUrl = derived(userDoc, ($userDoc) => (url: string) => {
//   if (!$userDoc?.affiliateId) return url;
//   return getNiceAffiliateLink($userDoc?.affiliateId) + `?goto=${url}`;
// });

export const replay = writable(0);
export const affiliateInBadge = writable(true);
export const hideWatermark = writable(false);

/**
 * Editable Confetti Settings
 */

export const currentPallete: Writable<AnimationPallete> = writable([]);
export const currentAnimationStyle: Writable<AnimationStyle> = writable();
export const currentAnimationPositions: Writable<AnimationPosition[]> = writable();
export const currentAnimationDuration: Writable<AnimationDuration> = writable();
export const currentAnimationAmount: Writable<AnimationAmount> = writable();
export const currentAnimationDensity: Writable<AnimationDensity> = writable([0.5]);
export const currentAnimationSize: Writable<AnimationSize> = writable([0.5]);
export const currentAnimationSprayStyle: Writable<AnimationSprayStyle> = writable('fr');
export const currentAnimationParticles: Writable<AnimationParticle[]> = writable([]);
export const currentAnimationFlippingIntensity: Writable<AnimationFlippingIntensity> =
  writable('high');
export const currentAnimationFallingSpeed: Writable<AnimationFallingSpeed> = writable('fast');

export async function recreateConfetti(confetti: ConfettiDoc) {
  console.log('recreateConfetti', confetti);
  if (confetti.animation?.pallete) currentPallete.set(confetti.animation.pallete);
  if (confetti.animation?.style) currentAnimationStyle.set(confetti.animation.style);
  if (confetti.animation?.positions) currentAnimationPositions.set(confetti.animation.positions);
  if (confetti.animation?.duration) currentAnimationDuration.set(confetti.animation.duration);
  if (confetti.animation?.amount) currentAnimationAmount.set(confetti.animation.amount);
  if (confetti.animation?.density) currentAnimationDensity.set(confetti.animation.density);
  if (confetti.animation?.size) currentAnimationSize.set(confetti.animation.size);
  if (confetti.animation?.sprayStyle) currentAnimationSprayStyle.set(confetti.animation.sprayStyle);
  if (confetti.animation?.particles) currentAnimationParticles.set(confetti.animation.particles);
  if (confetti.animation?.flippingIntensity)
    currentAnimationFlippingIntensity.set(confetti.animation.flippingIntensity);
  if (confetti.animation?.fallingSpeed)
    currentAnimationFallingSpeed.set(confetti.animation.fallingSpeed);
  previewUrlInput.set(confetti.site || '');
}

/**
 * Derived
 */

export const currentAnimation: Readable<ConfettiAnimation> = derived(
  [
    currentPallete,
    currentAnimationStyle,
    currentAnimationPositions,
    currentAnimationDuration,
    currentAnimationAmount,
    currentAnimationDensity,
    currentAnimationSize,
    currentAnimationSprayStyle,
    currentAnimationParticles,
    currentAnimationFlippingIntensity,
    currentAnimationFallingSpeed
  ],
  ([
    $currentPallete,
    $currentAnimationStyle,
    $currentAnimationPositions,
    $currentAnimationDuration,
    $currentAnimationAmount,
    $currentAnimationDensity,
    $currentAnimationSize,
    $currentAnimationSprayStyle,
    $currentAnimationParticles,
    $currentAnimationFlippingIntensity,
    $currentAnimationFallingSpeed,
  ]) => {
    return <ConfettiAnimation>{
      pallete: $currentPallete,
      style: $currentAnimationStyle,
      positions: $currentAnimationPositions,
      duration: $currentAnimationDuration,
      amount: $currentAnimationAmount,
      density: $currentAnimationDensity,
      size: $currentAnimationSize,
      sprayStyle: $currentAnimationSprayStyle,
      particles: $currentAnimationParticles,
      flippingIntensity: $currentAnimationFlippingIntensity,
      fallingSpeed: $currentAnimationFallingSpeed
    };
  }
);

export const currentConfettiObject: Readable<ConfettiDoc> = derived(
  [currentAnimation, previewUrl, niceUrl, affiliateInBadge, userDoc],
  ([$currentAnimation, $previewUrl, $niceUrl, $affiliateInBadge, $userDoc]) => {
    return {
      animation: $currentAnimation,
      ...($previewUrl ? { site: $previewUrl } : {}),
      ...($niceUrl ? { niceUrl: $niceUrl } : {}),
      ...($affiliateInBadge && $userDoc?.affiliateId
        ? { affiliateInBadge: $affiliateInBadge }
        : {}),
      ...($userDoc?.affiliateId ? { affiliateId: $userDoc?.affiliateId } : {}),
      lang: $userDoc?.lang || languageTag(),
      ownerUid: $userDoc?.uid
    };
  }
);
