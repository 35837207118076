/**
 * Animation options
 */

import { confettiParticles } from "./ConfettiParticles";

export const animationStyles_OLD_MAP = {
  'rain-p5': "rain_p5",
  'fireworks-canvas': "fireworks_canvas",
  'pop-canvas': "pop_canvas",
  'spray-canvas': "spray_canvas"
};


export const animationStyles = [
  'rain_p5',
  'fireworks_canvas',
  'pop_canvas',
  'spray_canvas'
] as const;

// t=top l=left r=right b=bottom
export const animationPositions = ['tl', 'tm', 'tr', 'ml', 'mm', 'mr', 'bl', 'bm', 'br'] as const;

// 'shorter',
export const animationDurations = ['short', 'medium', 'long', 'infinite'] as const;

export const animationAmounts = ['few', 'some', 'many'] as const;

// Spray Style
// t=top b=bottom m=middle fr=fountains-right fr=fountains-left
export const animationSprayStyles = ['t', 'b', 'm', 'fr', 'fl'] as const;

export const DENSITY_PIPS = 5;
export const SIZE_PIPS = 5;

// Get the keys as an array and type cast it
export const animationParticles = Object.keys(confettiParticles) as AnimationParticle[];

export const animationFlippingIntensities = ['no', 'low', 'high'] as const;

export const animationFallingSpeed = ['slow', 'fast'] as const;

/**
 * Exported types
 */

export type AnimationPallete = string[];
export type AnimationStyle = (typeof animationStyles)[number];
export type AnimationPosition = (typeof animationPositions)[number];
export type AnimationDuration = (typeof animationDurations)[number];
export type AnimationAmount = (typeof animationAmounts)[number];
export type AnimationDensity = [number];
export type AnimationSize = [number];
export type AnimationSprayStyle = (typeof animationSprayStyles)[number];
export type AnimationParticle = keyof typeof confettiParticles;
export type AnimationFlippingIntensity = (typeof animationFlippingIntensities)[number];
export type AnimationFallingSpeed = (typeof animationFallingSpeed)[number];

export type ConfettiAnimation = {
  style: AnimationStyle;
  pallete: AnimationPallete;
  positions: AnimationPosition[];
  duration: AnimationDuration;
  amount: AnimationAmount;
  density: AnimationDensity;
  size: AnimationSize;
  sprayStyle: AnimationSprayStyle;
  particles: AnimationParticle[];
  flippingIntensity: AnimationFlippingIntensity;
  fallingSpeed: AnimationFallingSpeed;
};
