import { PUBLIC_CONFETTI_ENV } from '$env/static/public';

export const confetti_env_production = PUBLIC_CONFETTI_ENV === 'production';
export const confetti_env_local = PUBLIC_CONFETTI_ENV === 'local';
export const confetti_env_staging = PUBLIC_CONFETTI_ENV === 'staging';

console.log({ PUBLIC_CONFETTI_ENV });

export const env_confetti_domain = confetti_env_production
  ? 'https://confettipage.com'
  : confetti_env_staging
  ? 'https://staging.confettipage.com'
  : confetti_env_local
  ? 'http://localhost:5173'
  : (() => {
      throw new Error('Unknown ENV, therefor unknown domain');
    })();
