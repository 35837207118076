<script lang="ts">
  import type { ConfettiAnimation } from '../ConfettiAnimation.types';
  import { random } from '../../vendor/svelte-canvas-confetti/utils/random';
  import ConfettiBurst from '../../vendor/svelte-canvas-confetti/ConfettiBurst.svelte';
  import {
    getColoredParticlesImages,
    responsiveParticleAmount,
    responsiveParticleSize
  } from '../ConfettiAnimation.functions';
  import { minMaxBy1 } from '../minMaxBy1';

  export let animation: ConfettiAnimation;
  export let w: number;
  export let h: number;
  
  $: fireworksSize = minMaxBy1(animation?.size?.[0], 8.5, 5, 8);
  $: fireworksDensity = minMaxBy1(animation?.density?.[0], 100, 13, 25, true);

  $: amount = responsiveParticleAmount(fireworksDensity);
  $: size = responsiveParticleSize(fireworksSize);

  $: console.log(size);
</script>

{#if animation.amount === 'few'}
  {#each Array.from({ length: 3 }, (_, i) => random(i)) as delay, i (i)}
    <ConfettiBurst
      origin={[random((w / 4) * 3, w / 4), random((h / 4) * 2, h / 4)]}
      particleCount={amount}
      styles={getColoredParticlesImages(animation.particles, animation.pallete, animation.size)}
      onCreate={(p) => ({ ...p, delay, w: size, h: size })}
    />
  {/each}
{/if}
{#if animation.amount === 'some'}
  {#each Array.from({ length: 5 }, (_, i) => random(i)) as delay, i (i)}
    <ConfettiBurst
      origin={[random((w / 4) * 3, w / 4), random((h / 4) * 2, h / 4)]}
      styles={getColoredParticlesImages(animation.particles, animation.pallete, animation.size)}
      particleCount={amount}
      onCreate={(p) => ({ ...p, delay, w: size, h: size })}
    />
  {/each}
{/if}
{#if animation.amount === 'many'}
  {#each Array.from({ length: 10 }, (_, i) => random(i)) as delay, i (i)}
    <ConfettiBurst
      origin={[random((w / 4) * 3, w / 4), random((h / 4) * 2, h / 4)]}
      styles={getColoredParticlesImages(animation.particles, animation.pallete, animation.size)}
      particleCount={amount}
      onCreate={(p) => ({ ...p, delay, w: size, h: size })}
    />
  {/each}
{/if}
