<script>
  import { onMount } from 'svelte';

  let shown = false;

  onMount(() =>
    requestAnimationFrame(() => {
      shown = true;
    })
  );
</script>

{#if shown}
  <slot />
{/if}
