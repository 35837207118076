import { derived, readable, type Readable } from 'svelte/store';
import { getRandom } from '../helpers/getRandom';
import { randomIntBetween } from '../helpers/randomIntBetween';
import {
  currentAnimationAmount,
  currentAnimationDensity,
  currentAnimationDuration,
  currentAnimationFallingSpeed,
  currentAnimationFlippingIntensity,
  currentAnimationParticles,
  currentAnimationPositions,
  currentAnimationSize,
  currentAnimationSprayStyle,
  currentAnimationStyle,
  currentPallete,
  persistedConfetti
} from './currentConfetti';
import {
  animationAmounts,
  animationDurations,
  type AnimationPosition,
  animationStyles,
  type AnimationDuration,
  animationPositions,
  type AnimationPallete,
  DENSITY_PIPS,
  SIZE_PIPS,
  animationSprayStyles,
  type AnimationStyle,
  animationParticles,
  type AnimationParticle,
  type AnimationSize,
  animationFlippingIntensities,
  type AnimationFlippingIntensity,
  type AnimationFallingSpeed,
  animationFallingSpeed
} from './ConfettiAnimation.types';
import { defaultColorPalletes } from '../components/elements/DefaultColorPalletes';
import { previewUrl, previewUrlInput } from '../../routes/(app)/start/urlStore';
import { minMaxBy1 } from './minMaxBy1';
import {
  confettiParticleGroups,
  confettiParticleGroupsKeys,
  confettiParticles
} from './ConfettiParticles';
import { average } from '../helpers/avarage';
import { browser } from '$app/environment';
import { userDoc } from '../db/firebase';

export const randomizePallete: Readable<VoidFunction> = derived(
  currentPallete,
  ($currentPallete) => {
    return () => {
      const otherPalletes: AnimationPallete[] = defaultColorPalletes.filter(
        (pallete) => pallete.join('') !== $currentPallete.join('')
      );
      currentPallete.set(<AnimationPallete>getRandom(otherPalletes));
    };
  }
);

export const randomizeAmount: Readable<VoidFunction> = derived(
  currentAnimationAmount,
  ($currentAnimationAmount) => {
    return () => {
      const other = animationAmounts.filter((pos) => pos !== $currentAnimationAmount);
      currentAnimationAmount.set(getRandom(other));
    };
  }
);
export const randomizeDuration: Readable<VoidFunction> = derived(
  currentAnimationDuration,
  ($currentAnimationDuration) => {
    return () => {
      const other = animationDurations.filter((pos) => pos !== $currentAnimationDuration);
      currentAnimationDuration.set(getRandom(other));
    };
  }
);

export function randomizeAnimationFlippingIntensity() {
  currentAnimationFlippingIntensity.set(getRandom(animationFlippingIntensities));
}

export const randomizeAnimationStyle: Readable<VoidFunction> = derived(
  currentAnimationStyle,
  ($currentAnimationStyle) => {
    return () => {
      const otherStyles = <
        AnimationStyle[] // Increase the spray-canvas potential by 1.5
      >[...animationStyles, 'spray-canvas'].filter((style) => style !== $currentAnimationStyle);
      currentAnimationStyle.set(getRandom(otherStyles));
      randomizeAnimationFlippingIntensity();
    };
  }
);

export const randomizePositions: Readable<VoidFunction> = derived(
  currentAnimationPositions,
  ($currentAnimationPositions) => {
    return () => {
      const amount = randomIntBetween(1, 3);
      // console.log('amount', amount);

      const newPositions: AnimationPosition[] = [];
      [...Array(amount).keys()].forEach((i) => {
        const unusedPositions = animationPositions.filter((pos) => !newPositions?.includes(pos));
        newPositions.push(getRandom(unusedPositions));
      });

      currentAnimationPositions.set(newPositions);
    };
  }
);

export const randomizeDensity: Readable<VoidFunction> = derived(
  currentAnimationDensity,
  ($currentAnimationDensity) => () => {
    let newDensity: number;
    do {
      newDensity =
        Math.round((randomIntBetween(0, DENSITY_PIPS - 1) / (DENSITY_PIPS - 1)) * 100) / 100;
      console.log({ newDensity, oldDensity: $currentAnimationDensity[0] });
    } while (newDensity === $currentAnimationDensity[0]);
    currentAnimationDensity.set([newDensity]);
  }
);
export const randomizeSize: Readable<VoidFunction> = derived(
  currentAnimationSize,
  ($currentAnimationSize) => () => {
    let newSize: number;
    do {
      newSize = Math.round((randomIntBetween(0, SIZE_PIPS - 1) / (SIZE_PIPS - 1)) * 100) / 100;
      console.log({ newSize, oldSize: $currentAnimationSize[0] });
    } while (newSize === $currentAnimationSize[0]);
    currentAnimationSize.set([newSize]);
  }
);
export const randomizeSprayStyle: Readable<VoidFunction> = derived(
  currentAnimationSprayStyle,
  ($currentAnimationSprayStyle) => {
    return () => {
      const other = animationSprayStyles.filter((style) => style !== $currentAnimationSprayStyle);
      currentAnimationSprayStyle.set(getRandom(other));
    };
  }
);

export const randomizeParticlesByGroup = derived(userDoc, ($userDoc) => {
  return () => {
    if (!$userDoc?.isBetaTester) {
      // clean
      currentAnimationParticles.set([]);
      return;
    }
    currentAnimationParticles.set(
      confettiParticleGroups[
        confettiParticleGroupsKeys[randomIntBetween(0, confettiParticleGroupsKeys.length - 1)]
      ]
    );
  };
});
export const MAX_PARTICLES = 6;
export const randomizeParticles: Readable<VoidFunction> = derived(
  [currentAnimationParticles, userDoc],
  ([$currentAnimationParticles, $userDoc]) => {
    return () => {
      if (!$userDoc?.isBetaTester) {
        currentAnimationParticles.set([]);
        return;
      }
      const amount = randomIntBetween(0, MAX_PARTICLES);
      // console.log('amount', amount);

      const newParticles: AnimationParticle[] = [];
      [...Array(amount).keys()].forEach((i) => {
        const unusedParticles = animationParticles.filter(
          (particle) => !newParticles?.includes(particle)
        );
        newParticles.push(getRandom(unusedParticles));
      });

      currentAnimationParticles.set(newParticles);
    };
  }
);

export const randomizeParticleAnimation: Readable<VoidFunction> = derived(
  [randomizeParticles, randomizeParticlesByGroup],
  ([$randomizeParticles, $randomizeParticlesByGroup]) =>
    () => {
      if (randomIntBetween(0, 1)) {
        // Randomize By Groups
        $randomizeParticlesByGroup();
      } else {
        // Randomize By Particles
        $randomizeParticles();
      }
    }
);

export const randomizeFallingSpeed: Readable<VoidFunction> = derived(
  [currentAnimationFallingSpeed, userDoc],
  ([$currentAnimationFallingSpeed, $userDoc]) =>
    () => {
      if ($userDoc?.isBetaTester) {
        currentAnimationFallingSpeed.set(
          // This makes fast 3/4 and slow 1/4 chance
          <AnimationFallingSpeed>getRandom([...animationFallingSpeed, 'fast', 'fast'])
        );
      }
    }
);

export const randomizeAnimation: Readable<VoidFunction> = derived(
  [
    randomizeAnimationStyle,
    randomizePositions,
    randomizeAmount,
    randomizeDensity,
    randomizeSize,
    randomizeSprayStyle,
    randomizeParticles,
    randomizeFallingSpeed,
    currentAnimationStyle
  ],
  ([
    $randomizeAnimationStyle,
    $randomizePositions,
    $randomizeAmount,
    $randomizeDensity,
    $randomizeSize,
    $randomizeSprayStyle,
    $randomizeParticles,
    $randomizeFallingSpeed,
    $currentAnimationStyle
  ]) => {
    return () => {
      const otherStyles = <
        AnimationStyle[] // Increase the spray_canvas potential by 1.5
      >[...animationStyles, ...animationStyles, 'spray_canvas'].filter((style) => style !== $currentAnimationStyle);
      currentAnimationStyle.set(getRandom(otherStyles));
      $randomizePositions();
      $randomizeAmount();
      $randomizeSprayStyle();

      // Extra for fun
      $randomizeDensity();
      $randomizeSize();
      currentAnimationDuration.set(<AnimationDuration>getRandom([...animationDurations]));
      $randomizeFallingSpeed();
    };
  }
);

export const randomizeEverything: Readable<VoidFunction> = derived(
  [
    randomizePallete,
    randomizeAnimation,
    randomizeDensity,
    randomizeSize,
    randomizeParticleAnimation
  ],
  ([
      $randomizePallete,
      $randomizeAnimation,
      $randomizeDensity,
      $randomizeSize,
      $randomizeParticleAnimation
    ]) =>
    () => {
      $randomizePallete();
      $randomizeAnimation();
      $randomizeDensity();
      $randomizeSize();
      $randomizeParticleAnimation();
    }
);

export const retrieveFromPersistedConfetti: Readable<VoidFunction> = derived(
  persistedConfetti,
  ($persistedConfetti) => () => {
    if ($persistedConfetti) {
      console.log('$persistedConfetti', $persistedConfetti);
      if ($persistedConfetti.animation) {
        currentPallete.set($persistedConfetti.animation.pallete);
        currentAnimationStyle.set($persistedConfetti.animation.style);
        currentAnimationPositions.set($persistedConfetti.animation.positions);
        currentAnimationDuration.set($persistedConfetti.animation.duration);
        currentAnimationAmount.set($persistedConfetti.animation.amount);

        // Added later
        if ($persistedConfetti?.animation?.density)
          currentAnimationDensity.set($persistedConfetti.animation.density);
        if ($persistedConfetti?.animation?.size)
          currentAnimationSize.set($persistedConfetti.animation.size);
        if ($persistedConfetti?.animation?.sprayStyle)
          currentAnimationSprayStyle.set($persistedConfetti.animation.sprayStyle);
        if ($persistedConfetti?.animation?.particles)
          currentAnimationParticles.set($persistedConfetti.animation.particles);
        if ($persistedConfetti?.animation?.flippingIntensity)
          currentAnimationFlippingIntensity.set($persistedConfetti.animation.flippingIntensity);

        console.log({ $persistedConfetti });
        if ($persistedConfetti.site) previewUrlInput.set($persistedConfetti.site);
        if ($persistedConfetti.site) previewUrl.set($persistedConfetti.site);
      }
    }
  }
);

export function getParticleSvg(
  color: string = '000000',
  particle: AnimationParticle,
  animationSize: AnimationSize
) {
  if (!confettiParticles?.[particle]?.svg) return;
  const size = responsiveParticleSize(
    minMaxBy1(
      animationSize[0],
      confettiParticles[particle].max,
      confettiParticles[particle].min,
      average(confettiParticles[particle].max, confettiParticles[particle].min)
    )
  );
  const svg = confettiParticles[particle].svg
    .replaceAll('000000', color.replaceAll('#', ''))
    .replace(/width="\d+"/g, `width="${size}"`)
    .replace(/height="\d+"/g, `height="${size}"`);
  return svg;
}

function createColoredParticleBase64Src(
  color: string,
  particle: AnimationParticle,
  animationSize: AnimationSize
) {
  const svg = getParticleSvg(color, particle, animationSize);
  return 'data:image/svg+xml,' + encodeURIComponent(svg);
}

function createColoredParticleImage(
  color: string,
  particle: AnimationParticle,
  animationSize: AnimationSize
) {
  let img = new Image();
  // img.src = 'https://github.com/andreasmcdermott/svelte-canvas-confetti/blob/main/static/parachute.png?raw=true';
  img.src = createColoredParticleBase64Src(color, particle, animationSize);
  return img;
}

export function getColoredParticlesImages(
  animationParticles: AnimationParticle[],
  animationPallete: AnimationPallete,
  animationSize: AnimationSize
): HTMLImageElement[] | AnimationPallete {
  if (!animationParticles?.length) return animationPallete;

  let _particleImages: HTMLImageElement[] = [];

  animationParticles.forEach((particle) => {
    animationPallete.forEach((color) => {
      if (confettiParticles?.[particle]?.svg)
        _particleImages.push(createColoredParticleImage(color, particle, animationSize));
    });
  });

  return _particleImages;
}

export function getColoredParticlesImagesBase64URLs(
  animationParticles: AnimationParticle[],
  animationPallete: AnimationPallete,
  animationSize: AnimationSize
): string[] {
  if (!animationParticles?.length) return [];

  let base64images: string[] = [];

  animationParticles.forEach((particle) => {
    animationPallete.forEach((color) => {
      if (confettiParticles?.[particle]?.svg)
        base64images.push(createColoredParticleBase64Src(color, particle, animationSize));
    });
  });

  return base64images;
}

export function responsiveParticleAmount(number: number) {
  const isMobile = window.innerWidth < 480;

  if (!isMobile) return number;

  // Normalize the number to [0, 1], assuming 300 is the max you'd generally pass
  let normalized = number / 30;

  // Apply square root for the curve
  let scaled = Math.sqrt(normalized);

  // Scale it back up to your original range.
  let result = scaled * 30;

  // Make sure you don't go below a minimum threshold, say 10.
  return Math.max(result, 10);
}

export function responsiveParticleSize(number: number) {
  if (!browser) return number;

  let x = 1;
  const isMobile = window.innerWidth < 480;
  if (isMobile) x = 0.7;

  return number * x;
}
